<template>
  <div class="readme-article">
    <h1 id="系统首页">一、系统首页</h1>
    <h2 id="数据统计">1.数据统计</h2>
    <p>
      系统数据统计，包括客户统计和群统计的总数，每日新增流失情况；可通过时间、员工筛选获取客户量情况。
    </p>
    <p>
      <img src="@/assets/img/scrm/1-1.png" />
    </p>
    <h2 id="事件提醒">2.事件提醒</h2>
    <p>
      系统日志，展示系统事件列表，点击查看更多可分页查看历史事件。
    </p>
    <p>
      <img src="@/assets/img/scrm/1-2.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Scrm1-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>